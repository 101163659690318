export default function (inputSlug: MaybeRef<string | string[]> = '') {
  /**
   * A type-safe slug, which is always an array of strings
   */
  const slugArray = computed(() => {
    const _slug = unref(inputSlug) || useRoute().params.slug
    if (!_slug) return ['home']
    return Array.isArray(_slug) ? _slug : [_slug]
  })

  /**
   * A string version of the slug
   */
  const slugString = computed(() => slugArray.value.join('/'))

  // Omit the last n elements of the slug
  const omit = (count: number) => {
    return slugArray.value.slice(0, slugArray.value.length - count)
  }

  return { slugArray, slugString, omit }
}
